import React from 'react';
import AppStoreBadge from '../assets/app-store-badge.svg'; // Assuming you have this SVG
import ChromeBadge from '../assets/chrome-webstore-badge.png'; // Assuming you have this image
import SafariIcon from '../assets/safari-icon.svg'; // Assuming you have this SVG

const HeroSection = () => {
  return (
    <section className="bg-black text-white flex flex-col justify-center h-full p-8 md:p-16">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-5xl font-bold mb-4 leading-tight">
          Streamline Your Job Application Process with Drop2Fill 
          Extension
        </h1>
        <p className="text-xl mb-8">Simplify and Accelerate Your Job Applications</p>
        <div className="flex justify-center space-x-4">
          <a href="https://apps.apple.com/au/app/drop2fill/id6517352565?mt=12" target="_blank" rel="noopener noreferrer">
            <img src={AppStoreBadge} alt="Download on the App Store" className="h-12 mx-4" />
          </a>
          <a href="https://chromewebstore.google.com/detail/drop2fill/likcffhionahoapeecccdjfpagjeodcf" target="_blank" rel="noopener noreferrer">
            <img src={ChromeBadge} alt="Download from Chrome Web Store" className="h-12 mx-4" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
